@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --primary-color: #1a7ac2;
  --secondary-color: #3a3fa2;
}
body{
  font-family: 'Poppins', sans-serif;
  background-image: url(./img/blob-scene-haikei\ \(1\).svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
body.offcanvas-active {
  overflow: hidden;
}

.close ::before{
  content: 'X';
  visibility: visible;
  color: "black";
  font-weight: bold;
}

.sr-only::before{
  visibility: hidden;
}

.close{
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 32px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}