
.primary-bg-color {
  background-color: #0076d1;
}
.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

/* ============ mobile view ============ */
@media all and (max-width: 991px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-offcanvas .container,
  .mobile-offcanvas .container-fluid {
    display: block;
  }
}
.rounded-4 {
  border-radius: 20px;
}
.h-25v{
  height: 200px;
}
.alert-fixed {
  position: fixed;
  top: 15px;
  right: 15px;
}

.nav-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: auto;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  color: #444444;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
  background-color: #eeeeee;
}

.nav__link--active {
  color: #009578;
}

.nav__icon {
  font-size: 20px;
}
